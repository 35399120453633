@import url('https://fonts.googleapis.com/css?family=Ubuntu');

* {
  box-sizing: border-box;
}

html,body {
  min-width:304px;
  position:relative;
  height:auto;
  min-height:100vh !important;
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
body{
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.55),
    rgba(0, 0, 0, 0.55)
  ), url('./img/wordcloudSpanish.png');
  background-repeat: no-repeat;
  background-position: 50% 0;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}

:root {
  --font-size: calc(10px + 1vmin);
}

#root{
  height:auto;
  min-height:100vh !important;
}

.App{
  height:auto;
  min-height:100vh !important;
}


@import url(https://fonts.googleapis.com/css?family=Ubuntu);
.header-nav{
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:space-between;
          justify-content:space-between;
  -webkit-align-items:center;
          align-items:center;
  background-color:#D0AA3A;
  background-color:#AEA764;
  background-color:rgb(250, 244, 195);
  padding:0 1em;
}

.header-nav a{
  text-decoration: none;
  display:inline-block;
  position:relative;
  color:#4A2D0F;
  margin:0 5px;
}

.header-nav a:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: -3px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: black;
  -webkit-transition: width 0.3s ease 0s, left 0.3s ease 0s;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.header-nav a:hover:after { 
  width: 100%; 
  left: 0; 
}
.header-right{
  font-weight:600;

}
nav{
  display:inline-block;
}

.Button:hover{
  -webkit-filter: brightness(70%);
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}
.SignupForm{
  text-align:left;
  padding:0px 10px;
}

.SignupForm input{
  width:100%;
  padding:5px;
  border:0;
  border-radius:3px;
}

.SignupButton{
  padding:5px;
  width:100%;
  border:0;
  border-radius:3px;
  background-color:rgb(0, 201, 251);
}

.SignupBox{
  width:300px;
  position:relative;
  margin:7vh auto;
  padding:1px;
  background-color:#D0AA3A;
  background-color:#AEA764;
  background-color:rgb(250, 244, 195);
  text-align:center;
  border-radius:2%;
  padding-bottom:28px;
}

.SignupLineBreakDiv{
  width:100%;
  height:1em;
}

.registration-have-acc{
  color:black;
}

.LoginForm{
  text-align:left;
  padding:0px 10px;
}

.LoginForm input{
  width:100%;
  padding:5px;
  border:0;
  border-radius:3px;
}

.LoginButton{
  padding:5px;
  width:100%;
  border:0;
  border-radius:3px;
  background-color:rgb(0, 201, 251);
}


.LoginBox{
  width:300px;
  position:relative;
  margin:7vh auto;
  padding:1px;
  background-color:#D0AA3A;
  background-color:#AEA764;
  background-color:rgb(250, 244, 195);
  text-align:center;
  border-radius:2%;
  padding-bottom:28px;
}



.DashboardSection{
  margin:0 auto;
  margin-top: 7vh;
  width:80%;
  height:100%;
  min-width:300px;
  max-width:800px;
  padding:1px 15px;
  padding-bottom:50px;
  margin-bottom:7vh;
  /* background-image: linear-gradient(
    rgba(208, 170, 58, 0.65),
    rgba(174, 167, 100, 0.65)
  ); */
  /* background-image: linear-gradient(
    rgba(174, 167, 100),
    rgba(208, 170, 58)
  ); */
  background-color: rgb(250, 244, 195)
}

.DashboardSection .header-undertext{
  font-size:70%;
  display:block;
  font-weight:600;
}
.dashboard-words-div{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
          flex-direction:column;
  padding:5px;
}
.deleteButton{
  height: 14px;
  margin-top: 3px;
}

.deleteButton:hover {
  cursor: pointer;
}

.addWordForm{
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.AddWord_content{
  background-color: rgb(250, 244, 195);
  margin: auto;
  padding: 0 20px 20px 20px;
  border: 1px solid #888;
  width: 300px;
}

.addInput {
  margin-bottom: 10px;
}

.AddWord__buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

.current-score{
  font-size: 14px;
}

.word-row-div{
  display:-webkit-flex;
  display:flex;
}

.word-row-original{
  -webkit-flex-basis:40%;
          flex-basis:40%;
  word-break: break-all; 
  word-wrap: break-word;
  padding-right:5px;
}
.word-row-translation{
  
  -webkit-flex-basis:40%;
  
          flex-basis:40%;
  word-break: break-all; 
  word-wrap: break-word;
  padding-right:5px;
}
.word-row-correct{
  
  -webkit-flex-basis:20%;
  
          flex-basis:20%;
  word-break: break-all; 
  word-wrap: break-word;
}
.DashboardSection h3{
  margin-bottom:0px;
}

.green{
  color:green;
  font-weight:600;
}
.red{
  color:rgb(184, 0, 0);
  font-weight:600;
}

.Lang-words-div{
  border:1px solid black;
  padding:5px;
  margin-bottom:20px;
  position:relative;
  background-color: #FFF07A;
}
.Lang-words-div:nth-child(even) {
  background-color: #CCC79F;
}

.DELETE-language{
  position:absolute;
  top:0;
  right:0;
  color:red;
  font-weight:600;
}

.Lang-words-div h3{
  margin-top:15px;
  word-break: break-all; 
  word-wrap: break-word;
}
.add-new-lang-input-form{
  margin-top:10px;
}
#add-new-lang-input{
  padding:5px;
  margin-bottom:10px;
  width:100%;
}

.startPrac {
  display: inline-block;
  border-radius: 4px;
  background-color: green;
  border: none;
  color: rgb(250, 244, 195);
  text-align: center;
  font-size: 18px;
  padding: 5px;
  width: 160px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.startPrac span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.startPrac span:after {
  content: '\BB';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.startPrac:hover span {
  padding-right: 25px;
}

.startPrac:hover span:after {
  opacity: 1;
  right: 0;
}
.button {
  background-color: rgb(250, 244, 195); 
  color: black; 
  border: 2px solid green;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button1 {
  border: 2px solid red;
}

.button:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}
.learnWordSection{
  margin:0 auto;
  margin-top: 7vh;
  width:80%;
  min-width:300px;
  max-width:800px;
  padding:1px 15px;
  padding-bottom:50px;
  /* background-image: linear-gradient(
    rgba(208, 170, 58, 0.65),
    rgba(174, 167, 100, 0.65)
  ); */
  /* background-image: linear-gradient(
    rgba(174, 167, 100),
    rgba(208, 170, 58)
  ); */
  background-color:rgb(250, 244, 195);
  text-align:center;
}
.learn-error{
  color:rgb(136, 0, 0);
  font-weight:600;
}
#learn-guess-input{
  padding:5px;
}

.learn-submit-guess-button{
  margin-top:5px;
  padding: .5em 1em;
}
.page-not-found-section{
  margin:0 auto;
  margin-top: 7vh;
  width:80%;
  height:100%;
  min-width:300px;
  max-width:800px;
  padding:1px 15px;
  padding-bottom:50px;
  margin-bottom:7vh;
  /* background-image: linear-gradient(
    rgba(208, 170, 58, 0.65),
    rgba(174, 167, 100, 0.65)
  ); */
  /* background-image: linear-gradient(
    rgba(174, 167, 100),
    rgba(208, 170, 58)
  ); */
  background-color: rgb(250, 244, 195);
  color:rgb(184, 0, 0);
}
/* main{
  padding:1px;
} */

.App{
  display:-webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
          flex-direction:column;
}
main{
  -webkit-flex:1 1;
          flex:1 1;
  position:relative;
}
* {
  box-sizing: border-box;
}

html,body {
  min-width:304px;
  position:relative;
  height:auto;
  min-height:100vh !important;
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
body{
  background-image: -webkit-linear-gradient(
    rgba(0, 0, 0, 0.55),
    rgba(0, 0, 0, 0.55)
  ), url(/static/media/wordcloudSpanish.433799a3.png);
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.55),
    rgba(0, 0, 0, 0.55)
  ), url(/static/media/wordcloudSpanish.433799a3.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  -ms-background-size: cover;
  background-size: cover;
}

:root {
  --font-size: calc(10px + 1vmin);
}

#root{
  height:auto;
  min-height:100vh !important;
}

.App{
  height:auto;
  min-height:100vh !important;
}



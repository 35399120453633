.learnWordSection{
  margin:0 auto;
  margin-top: 7vh;
  width:80%;
  min-width:300px;
  max-width:800px;
  padding:1px 15px;
  padding-bottom:50px;
  /* background-image: linear-gradient(
    rgba(208, 170, 58, 0.65),
    rgba(174, 167, 100, 0.65)
  ); */
  /* background-image: linear-gradient(
    rgba(174, 167, 100),
    rgba(208, 170, 58)
  ); */
  background-color:rgb(250, 244, 195);
  text-align:center;
}
.learn-error{
  color:rgb(136, 0, 0);
  font-weight:600;
}
#learn-guess-input{
  padding:5px;
}

.learn-submit-guess-button{
  margin-top:5px;
  padding: .5em 1em;
}
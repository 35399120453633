.header-nav{
  display:flex;
  justify-content:space-between;
  align-items:center;
  background-color:#D0AA3A;
  background-color:#AEA764;
  background-color:rgb(250, 244, 195);
  padding:0 1em;
}

.header-nav a{
  text-decoration: none;
  display:inline-block;
  position:relative;
  color:#4A2D0F;
  margin:0 5px;
}

.header-nav a:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: -3px;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: black;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.header-nav a:hover:after { 
  width: 100%; 
  left: 0; 
}
.header-right{
  font-weight:600;

}
nav{
  display:inline-block;
}
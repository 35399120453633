.SignupForm{
  text-align:left;
  padding:0px 10px;
}

.SignupForm input{
  width:100%;
  padding:5px;
  border:0;
  border-radius:3px;
}

.SignupButton{
  padding:5px;
  width:100%;
  border:0;
  border-radius:3px;
  background-color:rgb(0, 201, 251);
}

.SignupBox{
  width:300px;
  position:relative;
  margin:7vh auto;
  padding:1px;
  background-color:#D0AA3A;
  background-color:#AEA764;
  background-color:rgb(250, 244, 195);
  text-align:center;
  border-radius:2%;
  padding-bottom:28px;
}

.SignupLineBreakDiv{
  width:100%;
  height:1em;
}

.registration-have-acc{
  color:black;
}

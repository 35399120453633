.DashboardSection{
  margin:0 auto;
  margin-top: 7vh;
  width:80%;
  height:100%;
  min-width:300px;
  max-width:800px;
  padding:1px 15px;
  padding-bottom:50px;
  margin-bottom:7vh;
  /* background-image: linear-gradient(
    rgba(208, 170, 58, 0.65),
    rgba(174, 167, 100, 0.65)
  ); */
  /* background-image: linear-gradient(
    rgba(174, 167, 100),
    rgba(208, 170, 58)
  ); */
  background-color: rgb(250, 244, 195)
}

.DashboardSection .header-undertext{
  font-size:70%;
  display:block;
  font-weight:600;
}
.dashboard-words-div{
  display:flex;
  flex-direction:column;
  padding:5px;
}
.deleteButton{
  height: 14px;
  margin-top: 3px;
}

.deleteButton:hover {
  cursor: pointer;
}

.addWordForm{
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.AddWord_content{
  background-color: rgb(250, 244, 195);
  margin: auto;
  padding: 0 20px 20px 20px;
  border: 1px solid #888;
  width: 300px;
}

.addInput {
  margin-bottom: 10px;
}

.AddWord__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.current-score{
  font-size: 14px;
}

.word-row-div{
  display:flex;
}

.word-row-original{
  flex-basis:40%;
  word-break: break-all; 
  word-wrap: break-word;
  padding-right:5px;
}
.word-row-translation{
  
  flex-basis:40%;
  word-break: break-all; 
  word-wrap: break-word;
  padding-right:5px;
}
.word-row-correct{
  
  flex-basis:20%;
  word-break: break-all; 
  word-wrap: break-word;
}
.DashboardSection h3{
  margin-bottom:0px;
}

.green{
  color:green;
  font-weight:600;
}
.red{
  color:rgb(184, 0, 0);
  font-weight:600;
}

.Lang-words-div{
  border:1px solid black;
  padding:5px;
  margin-bottom:20px;
  position:relative;
  background-color: #FFF07A;
}
.Lang-words-div:nth-child(even) {
  background-color: #CCC79F;
}

.DELETE-language{
  position:absolute;
  top:0;
  right:0;
  color:red;
  font-weight:600;
}

.Lang-words-div h3{
  margin-top:15px;
  word-break: break-all; 
  word-wrap: break-word;
}
.add-new-lang-input-form{
  margin-top:10px;
}
#add-new-lang-input{
  padding:5px;
  margin-bottom:10px;
  width:100%;
}

.startPrac {
  display: inline-block;
  border-radius: 4px;
  background-color: green;
  border: none;
  color: rgb(250, 244, 195);
  text-align: center;
  font-size: 18px;
  padding: 5px;
  width: 160px;
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.startPrac span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.startPrac span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.startPrac:hover span {
  padding-right: 25px;
}

.startPrac:hover span:after {
  opacity: 1;
  right: 0;
}
.button {
  background-color: rgb(250, 244, 195); 
  color: black; 
  border: 2px solid green;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  cursor: pointer;
}

.button1 {
  border: 2px solid red;
}

.button:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}